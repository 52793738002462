<template>
  <div>
    <section class="section is-main-section p-4">
      <div class="p-0 box">
        <div class="table-filter m-0">
          <finance-datepicker :lang="datepicker.datePickerLang" v-mask="'##.##.####-##.##.####'" v-model="date"
            type="date" format="DD.MM.YYYY" />
          <b-button icon-left="sync" size="is-small" type="is-info" class="ml-2" @click="getDashboardDetail">{{ $t('def.filter') }}</b-button>
        </div>
      </div>
    </section>

<section class="section is-main-section p-4" v-if="$store.state.user.group === 'ALL'">
  <div class="tile is-ancestor is-flex-wrap-wrap">
    <div class="tile is-parent is-3" v-for="(item, key) in totals" :key="key">
      <article class="tile is-child">
        <card-component :title="`${!$store.state.user.siteId ? item.name + ' - ' : ''} ${item.method ? item.method + ' ' + '(' + item.count + ' ' + $t('menu.deposit') + ')' : '(' + item.count + ' ' + $t('menu.withdraw') +')'}`"
          :title-class="`${item.method ? 'has-background-success has-text-white' : 'has-background-danger has-text-white'}`">
          <div class="is-widget-label is-align-self-flex-start">
            <h1 class="title is-spaced mb-9">
              <growing-number :value="parseFloat(item.amount)" :prefix="'₺'" />
            </h1>
          </div>
          <div class="is-widget-label is-align-self-flex-start mt-2">
            <div class="subtitle is-7 mb-0">
              {{ $t('def.commission') }}
              <growing-number :value="parseFloat(item.commission)" :prefix="'₺'" />
            </div>
          </div>

        </card-component>
      </article>
    </div>
  </div>

</section>

<section class="section is-main-section p-4" v-if="$store.state.user.group !== 'ALL'">
  <div class="tile is-ancestor is-flex-wrap-wrap">
    <div class="tile is-parent is-3" v-for="(item, key) in totals" :key="key">
      <article class="tile is-child">
        <card-component :title="`${item.bankName || $t('menu.withdraw')} (${item.count} ${$t('def.quantity')})`"
          :title-class="`${item.bankName ? 'has-background-success has-text-white' : 'has-background-danger has-text-white'}`">
          <div class="is-widget-label is-align-self-flex-start">
            <h1 class="title is-spaced mb-9">
              <growing-number :value="parseFloat(item.total || 0)" :prefix="'₺'" />
            </h1>
          </div>
          <div class="is-widget-label is-align-self-flex-start mt-2">
            <div class="subtitle is-7 mb-0">
              <b>{{item.accountName}}</b>
            </div>
          </div>

        </card-component>
      </article>
    </div>
  </div>

</section>
  </div>
</template>

<script>
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import ClientsTableSample from '@/components/ClientsTableSample'
import GrowingNumber from '@/components/GrowingNumber.vue'
import { ReportService as Service } from '@/services'
import { startOfDay, subMinutes } from 'date-fns'

export default {
  name: 'Home',
  components: {
    CardComponent,
    CardWidget,
    Tiles,
    ClientsTableSample,
    GrowingNumber
  },
  data() {
    return {
      totals: [],
      date: new Date(),
      subMinutes,
      startOfDay
    }
  },
  mounted() {
    this.getDashboardDetail()
  },
  methods: {
    getDashboardDetail() {
      this.totals = []
      const startDate = this.getIstanbulDate(this.date)
      const endDate = this.getIstanbulDate(this.date, false)
      if (this.$store.state.user.group === 'ALL')
        Service.dashboard({ dates: [startDate, endDate] }).then(res => this.totals = res.data)
      else
        Service.dashboardGroup({ dates: [startDate, endDate] }).then(res => this.totals = res.data)
    },
  },
  watch: {
    'date': function () {
      this.getDashboardDetail()
    }
  }
}
</script>
